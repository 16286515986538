const searchValueLocaleStorage = () => {
  const SEARCH_VALUE_KEY = 'search-value';

  const getSearchValue = (localStorageKey: string) => {
    let cleanSearchValueArray = [];
    const searchValues = localStorage.getItem(localStorageKey);
    if (searchValues && searchValues.length > 0) {
      const searchValueArray = JSON.parse(searchValues);
      cleanSearchValueArray = searchValueArray.filter((value: string) => value !== '');
    }

    return cleanSearchValueArray;
  };

  const populateLocalStorage = (searchValue: string) => {
    const getSearchValue = JSON.parse(localStorage.getItem(SEARCH_VALUE_KEY) || '[]');
    if (!getSearchValue.includes(searchValue)) {
      getSearchValue.unshift(searchValue);
      localStorage.setItem(SEARCH_VALUE_KEY, JSON.stringify(getSearchValue));
    }
  };

  return {
    populateLocalStorage,
    getSearchValue,
  };
};

export default searchValueLocaleStorage;
